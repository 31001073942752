
.panelTitle {
    font-size: 25px;
    margin: 0;
    cursor: default;
}

.titleBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button {
    padding: 10px 22px;
    background-color: black;
    color: white;
    text-decoration: none;
    text-align: center;
    border-radius: 15px;
    border: 2px solid black;
    font-size: 12px;
    transition: 0.2s;
    cursor: pointer;
    margin: 1em 0;
}
.button:hover {
    background-color: white;
    color: black;
}

.housesTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}
.housesTable th, .housesTable td {
    border: 1px solid #e0e0e0;
    padding: 8px 12px;
    text-align: left;
}
.housesTable th {
    background-color: #f5f5f5;
}

.menuButton {
    background: none;
    color: black;
    text-decoration: none;
    text-align: center;
    border: none;
    font-size: 14px;
    transition: 0.2s;
    cursor: pointer;
}
.menuButton:hover {
    color: #888888;
}
