
/* Common */
.landingPage {
  width: 100%;
  background: #f9f9f9;
}

.landingContentContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0px;
  height: min-content;
  justify-content: center;
  overflow: visible;
  padding: 145px 0 0;
  position: relative;
  width: 100%;
  z-index: 1;
}

.blockContainer {
  width: 100%;
  max-width: 700px;
  box-sizing: border-box;
  margin: auto;
  margin-top: 1.6875rem;
  margin-bottom: 1.6875rem;
}

.text {
  font-size: 17px;
  line-height: 150%;
  font-weight: 500;
}

.blockTitle {
  font-size: 2.25rem;
  line-height: 2.5rem;
  margin: 0;
  padding: 0;
  text-align: center;
  font-weight: 500;
}

.buttonBox {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  text-align: center;
  justify-content: center;
}

@media only screen and (max-width: 480px) {
  .blockContainer {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .text {
    font-size: 19px;
    line-height: 150%;
  }
  .blockTitle {
    font-size: 40px;
    line-height: 2.5rem;
  }
  .buttonBox {
    margin-top: 50px;
  }
}

/* Header */
.headerBox {
  align-content: center;
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  height: min-content;
  justify-content: space-between;
  left: 50%;
  max-width: 1100px;
  overflow: visible;
  padding: 18px 0 0;
  position: fixed;
  top: 0;
  transform: translate(-50%);
  width: 100%;
  z-index: 2;
  box-sizing: border-box;
}

.headerIcon {
  width: 45px;
  cursor: pointer;
}

@media (max-width: 70.5rem) {
  .headerBox {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

/* First frame */
.frameFirstContainer{
  width: 100%;
  max-width: 850px;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  box-sizing: border-box;
}
.firstFrameTitle {
  font-size: 70px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -.02em;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  text-wrap: balance;
}
.firstFrameSubtitle {
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -.0026em;
  font-weight: 500;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 600px;
  text-align: center;
  text-wrap: balance;
}
.firstFrameButton {
  font-size: 14px;
  line-height: 100%;
  letter-spacing: .0016em;
  font-weight: 500;
  background-color: #080808;
  color: #ffffff;
  padding: 16px 24px;
  border-radius: 100px;
  cursor: pointer;
  transition: 0.2s;
  text-decoration: none;
}
.firstFrameButton:hover {
  background-color: #4e4e4e;
}

@media only screen and (max-width: 480px) {
  .frameFirstContainer {
    padding: 0 1.25rem;
    gap: 40px;
  }
  .firstFrameTitle {
    font-size: 45px;
    line-height: 110%;
    letter-spacing: -.0167em;
  }
  .firstFrameSubtitle {
    font-size: 21px;
    line-height: 130%;
    letter-spacing: -.0026em;
  }
  .firstFrameButton {
    font-size: 16px;
    padding: 18px 24px;
  }
}

/* Frames */
.frame {
  width: 100%;
  max-width: 1100px;
  box-sizing: border-box;
  position: relative;
  margin: 90px 0;
  overflow: hidden;
}

/* Second frame */
.frameSecondContainer {
  width: 100%;
  max-width: 1000px;
  box-sizing: border-box;
  position: relative;
  margin: 90px 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8rem;
}

@media only screen and (max-width: 480px) {
  .frameSecondContainer {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

/* Form */
.formContainer {
  width: 100%;
  max-width: 700px;
  box-sizing: border-box;
  position: relative;
  margin: 0;
  padding: 90px 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.formBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  box-sizing: border-box;
}

.formInput {
  outline-width: 0;
  border-radius: .28125rem;
  padding-left: .84375rem;
  padding-right: .84375rem;
  width: 100%;
  background: white;
  color: black;
  height: 2.5rem;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: #d1d1d1;
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 500;
  transition: 0.2s;
}

.formInput:focus {
  border-color: #080808;
}

@media only screen and (max-width: 480px) {
  .formContainer {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .formBox {
    gap: 1.5rem;
  }
  .formInput {
    height: 3rem;
    font-size: 15px;
  }
}

/* Footer */
.footerBox {
  left: 50%;
  max-width: 1100px;
  margin: 0;
  position: relative;
  transform: translate(-50%);
  width: 100%;
  box-sizing: border-box;
  padding-top: 70px;
  padding-bottom: 35px;
}

.footerText {
  font-size: 12px;
  font-weight: 500;
  color: #080808;
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .footerText {
    font-size: 14px;
  }
}
