
.formInput {
    display: block;
    width: 100%;
    border: none;
    padding: 10px;
    border-radius: 10px;
    margin: 1em 0;
    font-size: 13px;
    box-sizing: border-box;
    outline: none;
}

.formButton {
    display: block;
    background: none;
    cursor: pointer;
    color: white;
    padding: 12px 0;
    border-radius: 15px;
    border: none;
    transition: 0.2s;
    font-family: Montserrat;
    font-size: 12px;
    text-align: center;
    text-decoration: none;
    margin: 2em auto 0;
    width: 115px;
}
.formButton:hover {
    background: white;
    color: black;
}

.tabMenu {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}
.tabButton {
    cursor: pointer;
    padding: 10px;
    border: none;
    background: none;
    transition: 0.2s;
    color: white;
    font-weight: 600;
    border-radius: 12px;
    margin-right: 5px;
}
.tabButton:hover {
    background: white;
    color: black;
}
.tabButton.active {
    background: white;
    color: black;
}

.mqttData {
    margin: 50px 20px;
    cursor: default;
    font-size: 15px;
}

.text {
    font-size: 14px;
    margin-top: 30px;
    cursor: default;
}
