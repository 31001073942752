
.header {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.headerLeft {
    display: flex;
    width: 100%;
}

.buttonBox {
    width: 65px;
    flex-shrink: 0;
}

.starButton {
    width: 50px;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
    margin-top: 10px;
    cursor: pointer;
}

.popupTitle {
    font-size: 20px;
    margin: 0;
    color: black;
    display: inline;
}
.popupSubitle {
    font-size: 14px;
    margin: 0;
    margin-top: 5px;
    color: #9a9a9a;
}

.starIcon {
    color: #ccc;
    transition: 0.2s ease;
    stroke-width: 2.5;
    fill: #f0ad4e00;
}
.starIcon:hover {
    color: #f0ad4e;
}

.starIconActive {
    color: #f0ad4e;
    fill: #f0ad4e;
    transition: 0.2s ease;
    stroke-width: 2.5;
}
.starIconActive:hover {
    color: #f0ad4e;
}

.deleteButtonBox {
    width: 100%;
    margin-top: 50px;
    text-align: center;
}

.editButton {
    margin-left: 5px;
    color: black;
    transition: 0.2s;
    cursor: pointer;
    display: inline;
    vertical-align: middle;
    padding: 2px;
}
.editButton:hover {
    color: gray;
}

.editBox {
    background: #f4f4f5;
    border-radius: 25px;
    margin: 25px 0;
    padding-top: 5px;
}

.input {
    padding: 20px 25px;
    border-radius: 15px;
    width: 100%;
    outline: none;
    background: none;
    border: none;
    box-sizing: border-box;
    font-size: 14px;
    color: black;
}

.inputPosition {
    width: 50%;
}

.select {
    padding: 20px 25px;
    border-radius: 15px;
    width: calc(100% - 20px);
    outline: none;
    background: none;
    border: none;
    box-sizing: border-box;
    font-size: 14px;
    color: black;
    cursor: pointer;
}

.divider {
    width: calc(100% - 20px);
    margin: 0 10px;
    height: 1px;
    background-color: #ccc;
}

.editButtonsBox {
    display: flex;
    justify-content: space-between;
    padding: 10px 25px 25px;
}

.cancelButton {
    padding: 16px 40px;
    background-color: #9a9a9a;
    color: white;
    text-decoration: none;
    text-align: center;
    border-radius: 50px;
    border: none;
    font-size: 12px;
    transition: 0.2s;
    cursor: pointer;
    font-family: Montserrat;
}
.cancelButton:hover {
    background-color: #7a7a7a;
}

.saveButton {
    padding: 16px 40px;
    background-color: #1757ff;
    color: white;
    text-decoration: none;
    text-align: center;
    border-radius: 50px;
    border: none;
    font-size: 12px;
    transition: 0.2s;
    cursor: pointer;
    font-family: Montserrat;
}
.saveButton:hover {
    background-color: #0d3ebe;
}

.deleteButton {
    padding: 16px 40px;
    background-color: #ff7857;
    color: white;
    text-decoration: none;
    text-align: center;
    border-radius: 50px;
    border: none;
    font-size: 12px;
    transition: 0.2s;
    cursor: pointer;
    font-family: Montserrat;
}
.deleteButton:hover {
    background-color: #e65a3f;
}

.headerTextBox {
    text-wrap: balance;
}

/* Statuses */
.statusBox {
    background: #f4f4f5;
    border-radius: 30px;
    margin: 20px 0;
    padding: 12px 25px;
    box-sizing: border-box;
}

.statusItem {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    font-size: 15px;
    gap: 10px;
}

.statusKey {
    font-weight: bold;
    color: #333;
    letter-spacing: -0.15px;
}

.statusValue {
    color: #666;
    text-align: right;
}

.statusValueRed {
    color: #ff7857;
    font-weight: bold;
    text-align: right;
}

/* Remote Buttons */
.remoteButtonsContainer {
    padding: 15px;
}

.remoteButtonsBox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    grid-gap: 10px;
}

.remoteButton {
    background-color: #ffffff;
    color: #1757ff;
    border: none;
    padding: 12px 10px;
    border-radius: 100px;
    cursor: pointer;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    min-height: 60px;
    transition: 0.2s;
    font-family: Montserrat;
    box-sizing: border-box;
}

.remoteButton:active {
    background-color: #cacaca;
    transform: scale(0.95);
}

.remoteButtonAdd {
    color: #000000;
}

.remoteButtonAddNewBox {
    background: white;
    margin-top: 15px;
    padding: 15px;
    border-radius: 30px;
}

.remoteButtonAddNewTitle {
    font-size: 18px;
    margin: 5px 0 15px;
}

.remoteButtonAddNewText {
    font-size: 13px;
    margin: 5px 0;
}

.remoteButtonInputBox {
    margin: 25px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    grid-gap: 10px 12px;
}

.remoteButtonInputButton {
    background-color: #f4f4f5;
    color: #000000;
    border: none;
    padding: 12px 10px;
    border-radius: 100px;
    cursor: pointer;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    min-height: 60px;
    transition: 0.2s;
    font-family: Montserrat;
    box-sizing: border-box;
    text-align: center;
}
.remoteButtonInputButton::placeholder {
    color: #000000;
}

.remoteButtonInputButtonActive {
    background-color: #cacaca;
    color: #000000;
    border: none;
    padding: 12px 10px;
    border-radius: 100px;
    cursor: pointer;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    min-height: 60px;
    transition: 0.2s;
    font-family: Montserrat;
    box-sizing: border-box;
    text-align: center;
}

.remoteButtonAddNewButtonsBox {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.remoteButtonAddNew {
    background: #1757ff;
    color: white;
    min-height: auto;
    padding: 20px;
    width: 100%;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    transition: 0.2s;
    font-family: Montserrat;
    box-sizing: border-box;
}

.remoteButtonAddNew:hover {
    background: #0d3ebe;
}

.remoteButtonAddBack {
    background: #f4f4f5;
    color: #000000;
    min-height: auto;
    padding: 20px;
    width: 100%;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    transition: 0.2s;
    font-family: Montserrat;
    box-sizing: border-box;
}

.remoteButtonAddBack:hover {
    background: #cacaca;
}

.remoteButtonAddNewLoading {
    text-align: center;
    margin: 20px 0;
}

.errorMessage {
    color: #ff7857;
    font-size: 13px;
    margin: 5px 0;
    font-weight: bold;
}
