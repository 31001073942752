
.form {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    width: 900px;
    min-width: 55%;
    max-width: 100%;
    height: calc(100vh - 40px);
    background: black;
    color: white;
    margin: 15px;
    border-radius: 25px;
    padding: 2em;
    box-sizing: border-box;
    overflow: scroll;
}

.formTitle {
    font-size: 25px;
    margin: 0;
    cursor: default;
    margin-bottom: 1em;
}

.closeIconBtn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
}
.closeIcon {
    display: block;
    position: absolute;
    top: 2em;
    right: 3em;
    cursor: pointer;
    width: 20px;
    z-index: 3;
}
