
.root {
    display: flex;
    margin: 24px 0px;
    text-decoration: none;
    position: relative;
    justify-content: space-between;
}

.iconBox {
    border: none;
    width: 50px;
    height: 50px;
    min-width: 50px;
    flex-shrink: 0;
    border-radius: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background 0.1s ease-in-out;
    fill: background 0.1s ease-in-out;
    background: #f4f4f4;
    fill: black;
    color: black;
    cursor: pointer;
}

.textBox {
    padding-left: 15px;
    width: calc(100% - 100px);
    text-decoration: none;
    flex-grow: 1;
    cursor: pointer;
}

.title {
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    color: black;
}

.text {
    margin: 0;
    padding: 0;
    font-size: 12px;
    color: #9a9a9a;
    margin-top: 2px;
}

.buttonBox {
    flex-shrink: 0;
}
