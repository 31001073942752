
.title {
    cursor: default;
}

.floorBox {
    width: 600px;
    padding: 10px;
    background: white;
    border-radius: 60px;
    position: relative;
    overflow: hidden;
}

.floorImage {
    width: 100%;
    height: auto;
    position: relative;
}

.deviceBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
}

.number {
    color: white;
    font-weight: 600;
    margin: auto 10px auto 0;
    width: 60px;
    cursor: default;
}

.input {
    padding: 10px 15px;
    border-radius: 10px;
    width: 55px;
    outline: none;
    margin-right: 15px;
    background: white;
}

.textBox {
    display: flex;
}

.text {
    font-size: 14px;
    margin: 0;
    cursor: default;
    font-weight: 600;
}

.positionText {
    font-size: 14px;
    margin-right: 5px;
    cursor: default;
}

.buttonDevice {
    position: absolute;
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 20px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background 0.1s ease-in-out;
    background: #d5e6ff;
    color: #1757ff;
}

.floorImageBox {
    margin-bottom: 40px;
    margin-top: 40px;
}

.saveButton {
    cursor: pointer;
    padding: 10px 15px;
    transition: 0.2s;
    font-weight: 600;
    border-radius: 10px;
    margin-right: 5px;
    background: white;
    color: black;
    border: 2px solid white;
    margin: 10px 0;
    font-size: 13px;
}
.saveButton:hover {
    background: black;
    color: white;
}
