
.newConnectBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (min-width: 480px) {
    .buttonsBox {
        display: flex;
        flex-wrap: wrap;
        margin-top: 35px;
    }
}
@media only screen and (max-width: 480px) {
    .buttonsBox {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: center;
        grid-gap: 15px;
        grid-column-gap: 10px;
        grid-row-gap: 15px;
        margin-top: 35px;
        margin-bottom: 25px;
    }
}
