
.featuresContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 1050px;
}

.featuresText {
    width: 35%;
    margin-top: 30vh;
    margin-bottom: -30vh;
}

.featureBlock {
    min-height: 90vh;
    height: 90%;
    transition: opacity 0.4s ease;
}

.featureImage {
    width: 60%;
    height: 100vh;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.featureImage img {
    width: 100%;
    position: absolute;
    transition: opacity 0.4s ease;
    border-radius: 25px;
}

.title {
    color: #000;
    font-size: 72px;
    font-weight: 500;
    letter-spacing: -3px;
    margin: 0;
    text-align: center;
}
.title span {
    color: #1757ff;
}
.featureTitle {
    color: #000;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: -1px;
    margin: 25px 0;
}
.featureDescription {
    color: #000;
    font-size: 18px;
    font-weight: 400;
    margin: 25px 0 0;
    white-space: pre-wrap;
    text-wrap: pretty;
}

@media only screen and (max-width: 480px) {
    .featuresContainer {
        flex-direction: column-reverse;
    }
    .featureImage {
        width: 100%;
        height: 50vh;
        padding-top: 45px;
        margin-top: 0px;
        background: linear-gradient(180deg, white 50%, transparent 50%);
    }
    .featureImage img {
        width: 95%;
    }
    .featuresText {
        width: 85%;
        margin: 0 auto -35vh;
    }
    .featureBlock {
        min-height: 40vh;
        height: auto;
    }
    .title {
        font-size: 55px;
        margin-top: 25px;
    }
}
