
.title {
    font-family: Montserrat;
    font-size: 30px;
    margin: 1em;
    cursor: default;
}

.adminContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin: 2em 0;
}

.menuContainer {
    width: 20%;
    background: black;
    border-radius: 15px;
}

.panelContainer {
    width: 75%;
}

.menuItem {
    display: block;
    background: none;
    cursor: pointer;
    color: white;
    padding: 12px 0;
    border-radius: 15px;
    border: 3px solid black;
    transition: 0.2s;
    font-family: Montserrat;
    font-size: 12px;
    text-align: center;
    text-decoration: none;
}
.menuItem:hover {
    background: white;
    color: black;
}
