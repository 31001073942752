
.root {
    box-sizing: border-box;
}
@media only screen and (min-width: 480px) {
    .root {
        height: 100%;
    }
}

@media only screen and (min-width: 480px) {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 100px;
        width: 70%;
        margin: auto;
    }
    .container {
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        height: fit-content;
        box-sizing: border-box;
        padding-left: max(180px, 20%);
        padding-right: max(100px, 10%);
        padding-top: 30px;
        padding-bottom: 50px;
    }
    .allComponentsBox {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
    }
}
@media only screen and (max-width: 480px) {
    .header {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        padding: 0px 25px;
        align-items: center;
        min-height: 100px;
    }
    .container {
        margin: 0;
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 0 25px;
    }
    .allComponentsBox {
        width: 100%;
    }
}

.sideBlock {
    display: block;
    display: flex;
    align-items: center;
}

.time {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 26px;
    cursor: default;
    font-weight: 500;
    width: 67px;
    text-align: right;
    z-index: 100;
}
.divider {
    height: 24px;
    width: 1px;
    margin: 0 10px;
    background: white;
}
.companyTitle {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 11px;
    cursor: default;
    font-weight: 500;
}
.appTitle {
    margin: 0;
    padding: 0;
    font-size: 11px;
    cursor: default;
    font-weight: 500;
    color: #e3e3e3;
}

.dateAndScenariosRow {
    display: flex;
    justify-content: space-around;
    align-items: stretch; /* Выравнивание по высоте */
    margin: 0;
    margin-bottom: 25px;
}

.firstColumn {
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

@media only screen and (min-width: 480px) {
    .firstColumn {
        margin-right: 35px;
    }
}
