
.formInput {
    display: block;
    width: 100%;
    border: none;
    padding: 10px;
    border-radius: 10px;
    margin: 1em 0;
    font-size: 13px;
    box-sizing: border-box;
    outline: none;
    background: white;
}

.formButton {
    display: block;
    background: none;
    cursor: pointer;
    color: white;
    padding: 12px 0;
    border-radius: 15px;
    border: none;
    transition: 0.2s;
    font-family: Montserrat;
    font-size: 12px;
    text-align: center;
    text-decoration: none;
    margin: 2em auto 0;
    width: 115px;
}
.formButton:hover {
    background: white;
    color: black;
}
