.root {
    box-sizing: border-box;
}

@media only screen and (min-width: 480px) {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 100px;
        width: 70%;
        margin: auto;
    }
    .container {
        margin: 0;
        padding: 0;
        width: 100%;
        box-sizing: border-box;
        padding-left: 115px;
    }
}
@media only screen and (max-width: 480px) {
    .header {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        padding: 0px 25px;
        align-items: center;
        min-height: 100px;
    }
    .container {
        margin: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 0 25px;
    }
}

.title {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 20px;
    cursor: default;
}
.sideBlock {
    display: block;
    width: 25%;
}

.button {
    width: 100%;
    padding: 13px 20px;
    margin-bottom: 10px;
    cursor: pointer;
    color: white;
    border-radius: 15px;
    transition: 0.2s;
    font-size: 13px;
    font-family: Montserrat;
    text-align: left;
}

.active {
    background-color: white;
    color: #000000;
}

.container {
    display: flex;
    height: 100%;
}

@media only screen and (min-width: 768px) {
    .menu {
        width: 250px;
        padding: 20px;
        box-sizing: border-box;
        padding-top: 55px;
        flex-shrink: 0;

        position: sticky;
        height: 100vh;
        position: sticky;
        top: 0;
    }

    .content {
        flex-grow: 1;
        box-sizing: border-box;
        margin: 0 35px;
    }

    .button:hover {
        background-color: white;
        color: #000000;
    }
}
@media only screen and (max-width: 768px) {
    .root {
        flex-direction: column;
    }

    .menu {
        width: 100%;
        padding: 20px 0;
        box-sizing: border-box;
    }

    .content {
        flex-grow: 1;
        padding: 0;
        box-sizing: border-box;
        overflow-y: scroll;
        color: white;
    }

    .button {
        text-align: left;
    }
}

.userButton {
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 25px;
    cursor: pointer;
    background-color: white;
    color: #000000;
    border-radius: 20px;
    transition: 0.2s;
    font-size: 13px;
    font-family: Montserrat;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media only screen and (max-width: 768px) {
    .userButton {
        padding: 15px 20px;
    }
}
@media only screen and (min-width: 768px) {
    .userButton:hover {
        color: #4d4d4d;
    }
}

.userBlock {
    display: flex;
    align-items: center;
}

.userAvatar {
    width: 40px;
    border-radius: 100px;
    margin-right: 10px;
}

.userAvatarWait {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    margin-right: 10px;
    background-color: #c7c7c7;
}

.message {
    color: white;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    width: 95%;
    margin: auto;
    cursor: default;
}

.menuTitle {
    padding: 0;
    margin: 0;
    margin-left: 5px;
    margin-top: 20px;
    margin-bottom: 15px;
    cursor: default;
    color: white;
    font-size: 13px;
    font-family: Montserrat;
    text-align: left;
    font-weight: 600;
}
