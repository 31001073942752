
.title {
  font-family: Montserrat;
  font-size: 30px;
  margin: 1em 2em 0;
  cursor: default;
}

.houseContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 1em 2em;
}

.houseCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: black;
  margin: 1em;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 35px;
  transition: transform 0.2s ease-in-out;
  width: calc(25% - 4em - 5px);
  cursor: pointer;
}

.houseCard:hover {
  transform: scale(1.05);
}

.houseCard img {
  width: 100%;
  height: auto;
  border-radius: 25px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.houseName {
  margin-left: 10px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 480px) {
  .houseCard {
    width: 100%;
  }
}

.slideTitle {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.slideContent {
  font-size: 18px;
  text-align: center;
}

.noHouses {
  font-size: 18px;
  text-align: center;
  margin: 2em;
}
