
.block {
    flex-direction: column;
}

.box {
    display: flex;
    margin-top: 10px;
}

.username {
    margin-right: 25px;
    font-weight: 500;
    font-size: 15px;
}

.userButton {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-self: center;
}

.trashButton {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
    background: #f4f4f400;
    color: white;
}
.trashButton:hover {
    background: #f4f4f4;
    color: red;
}

.confirmButton {
    background: #f4f4f4;
    color: black;
}

.addUserButton {
    font-family: Montserrat;
    background: #1757ff;
    color: white;
    width: 240px;
    height: 50px;
    border-radius: 100px;
    margin: 25px 0;
    padding: 0;
    cursor: pointer;
    transition: 0.2s;
}
.addUserButton:hover {
    background: #0d3ebe;
}
