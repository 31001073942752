.capabilitiesBox {
    width: 100%;
    margin-top: 120px;
}

.title {
    color: #000;
    font-size: 72px;
    font-weight: 500;
    letter-spacing: -3px;
    margin: 0;
    text-align: center;
}

.capabilitiesContainer {
    width: 100%;
    max-width: 1050px;
    margin: 70px auto;
    box-sizing: border-box;
}

.row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
    gap: 30px;
}

.capabilityBlock1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
    border-radius: 25px;
    background-color: #f5f5f7;
    border: 1px #edeff3;
    color: #000;
    padding: 35px 0;
    box-sizing: border-box;
}

.integrationsImg {
    width: 100%;
    padding: 15px 10px 10px;
    box-sizing: border-box;
    margin-top: -25px;
}

.capabilityBlock2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 55%;
    border-radius: 25px;
    background-color: #f5f5f7;
    border: 1px #edeff3;
    color: #000;
    box-sizing: border-box;
}

.capabilityBlock3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    border-radius: 25px;
    background: #404040;
    color: white;
    box-sizing: border-box;
}

.iconAndText {
    padding: 0 50px;
    box-sizing: border-box;
}

.appScreenshot {
    max-width: 50%;
}

.capabilitiesBox h2 {
    font-size: 25px;
    font-weight: 500;
    margin: 20px 0;
}

.capabilitiesBox p {
    font-size: 16px;
    line-height: 1.5;
}


@media only screen and (max-width: 480px) {
    .capabilitiesBox {
        margin-top: 50px;
    }

    .title {
        font-size: 50px;
    }

    .capabilitiesContainer {
        margin: 55px 15px;
        width: calc(100% - 30px);
    }

    .integrationsImg {
        margin-top: -10px;
    }

    .row {
        flex-direction: column;
    }

    .capabilityBlock1 {
        width: 100%;
        padding: 35px 10px;
    }

    .capabilityBlock2 {
        width: 100%;
        padding: 35px 10px;
    }

    .capabilityBlock3 {
        width: 100%;
        flex-direction: column;
        padding: 35px 10px 0;
    }

    .iconAndText {
        padding: 0 20px;
    }

    .appScreenshot {
        max-width: 100%;
    }
}


/* Offer */
.capabilityBlockOffer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 25px;
    background-color: #f5f5f7;
    border: 1px #edeff3;
    color: #000;
    overflow: hidden;
    box-sizing: border-box;
}

.capabilityBlockOffer img {
    box-sizing: border-box;
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.offerText {
    padding: 20px 50px 30px;
    box-sizing: border-box;
}

@media only screen and (max-width: 480px) {
    .offerText {
        padding: 20px 30px 35px;
    }
}
