
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.houseImage {
  width: 100%;
  height: auto;
}

.houseDataBox {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 15px;
}

.dataBlock {
  border: 1px solid #e0e0e0;
  padding: 15px 25px 25px;
  border-radius: 15px;
  width: 350px;
}

.buttonBox {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
}

.button {
  padding: 15px 40px;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: 0.2s;
  font-size: 16px;
  text-decoration: none;
  display: block;
}

.aliceButton {
  background-color: #7956ff;
}
.aliceButton:hover {
  background-color: #6b4ae0;
}

.sberButton {
  background-color: #1fa038;
}
.sberButton:hover {
  background-color: #19892f;
}

.googleButton {
  background-color: #4285F4;
}
.googleButton:hover {
  background-color: #357ae8;
}

.vkButton {
  background-color: #2d81e0;
}
.vkButton:hover {
  background-color: #2a75c7;
}

.ctaButtonBox {
  margin: 25px 0 10px;
  text-align: center;
}
.ctaButton {
  display: inline-block;
  padding: 15px 35px;
  background-color: black;
  color: white;
  text-decoration: none;
  text-align: center;
  border-radius: 20px;
  border: 2px solid black;
  font-size: 14px;
  transition: 0.2s;
  cursor: pointer;
}
.ctaButton:hover {
  background-color: white;
  color: black;
}

.slideTitle {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.slideContent {
  font-size: 18px;
  text-align: center;
}
