
.root {
    margin-right: 0;
    margin-bottom: 15px;
    width: calc(100% + 20px);
    box-sizing: border-box;
    background: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

@media only screen and (min-width: 480px) {
    .root {
        max-width: 400px;
        margin-bottom: 0;
    }
}
@media only screen and (max-width: 480px) {
    .root {
        width: 100%;
    }
}

@media only screen and (min-width: 480px) {
    .buttonsBox {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: center;
        padding: 0 10px;
    }
}
@media only screen and (max-width: 480px) {
    .buttonsBox {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: center;
        grid-gap: 15px;
        grid-column-gap: 10px;
        grid-row-gap: 15px;
        width: 98%;
        margin: 0 auto 15px;
    }
}

.title {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 20px;
    cursor: default;
}

.headerBox {
    width: calc(100% - 20px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 0 25px;
    height: 40px;
}

@media only screen and (max-width: 480px) {
    .headerBox {
        width: 100%;
        margin: 0 0 10px;
    }
}

.button {
    background: none;
    color: white;
    padding: 13px 21px 14px;
    border-radius: 25px;
    border: none;
    box-shadow: none;
    cursor: pointer;
    font-size: 11px;
    font-family: Montserrat;
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.buttons {
    display: flex;
}

.helpButtonBox {
    display: flex;
    align-items: center;
    margin-right: 7px;
}
.helpButton {
    color: #bdbdbd;
    stroke-width: 1px;
    cursor: pointer;
}
