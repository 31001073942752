
.root {
    display: block;
    position: relative;
}

@media only screen and (min-width: 480px) {
    .main {
        display: block;
        height: 100vh;
    }
}
@media only screen and (max-width: 480px) {
    .main {
        padding-bottom: 125px;
    }
}

.fon {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.fon2 {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #b3c7e6, #5a9bd4);
}
.fon3 {
    width: 100%;
    margin-top: 1500px;
}
.fon4 {
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #8289bf, #b5acc9);
}
.fon5 {
    width: 100%;
    height: 100%;
    background: #000000;
}

.fonDefault {
    position: absolute;
    transition: opacity 0.2s ease-in-out;
    transition: background 0.2s ease-in-out;
}
.fons {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

@media only screen and (min-width: 480px) {
    .menu {
        position: fixed;
        left: 0;
        top: 0;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        padding-left: 25px;
        z-index: 100;
    }
}
@media only screen and (max-width: 480px) {
    .menu {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 30px;
        display: flex;
        width: auto;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 5px;
        box-sizing: border-box;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgb(0 0 0 / 20%);
        z-index: 100;
        border-radius: 100px;
        gap: 15px;
    }
}
