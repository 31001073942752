
.root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.2);
    box-sizing: content-box;
    padding: 20px 0;
    width: 180px;
    height: 140px;
    max-width: 60%;
}

@media only screen and (max-width: 480px) {
    .root {
        max-width: 50%;
    }
}

.row {
    display: flex;
    justify-content: space-between;
    width: 140px;
}

.firstRow {
    margin-bottom: 10px;
}

.button {
    display: flex;
    align-items: center;
    width: 65px;
    height: 65px;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.15);
    color: white;
    cursor: pointer;
    transition: background-color 0.2s;
    justify-content: center;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.active {
    background-color: white;
    color: black;
}

.activeShield {
    background-color: #ff7857;
    color: white;
}

.countdown {
    font-size: 24px;
    color: white;
    font-weight: 500;
}
