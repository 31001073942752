.App {
  width: 100%;
  height: 100%;
}

.icon {
  width: 60px;
}

.photo {
  width: 100%;
}
