.videoContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.videoPlayer {
  width: 100%;
  max-width: 100%;
  border-radius: .28125rem;
}

.playPauseButton {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffffcc;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}

.icon {
  color: #080808;
  fill: #080808;
}

@media (max-width: 70.5rem) {
  .videoContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .playPauseButton {
    top: 15px;
    right: calc(15px + 1rem);
  }
}
