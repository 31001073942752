
.root {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 25px 10px;
    background: white;
    border-radius: 50px;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: relative;
}

@media only screen and (min-width: 480px) {
    .root {
        max-width: 370px;
        height: fit-content;
    }
}

.title {
    margin: 0;
    padding: 0;
    color: #000000;
    font-size: 20px;
    cursor: default;
}

.name {
    padding: 0;
    color: #000000;
    font-size: 18px;
    cursor: default;
    margin: 5px 0 10px;
}

.buttonWithSeparator:after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background: #e0e0e0;
    position: absolute;
    right: 0;
    bottom: -12px;
}
.lastButton:after {
    display: none;
}

.headerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    height: 40px;
}

.button {
    background: #ffffff;
    color: #000000;
    padding: 12px 20px 13px;
    border-radius: 25px;
    border: 1px solid #bfbfbf;
    box-shadow: none;
    cursor: pointer;
    font-size: 11px;
    font-family: Montserrat;
}

.buttons {
    display: flex;
}

.helpButtonBox {
    display: flex;
    align-items: center;
    margin-right: 7px;
}
.helpButton {
    color: #bdbdbd;
    stroke-width: 1px;
    cursor: pointer;
}
