.comparisonBox {
    width: 100%;
    margin-top: 120px;
}

.title {
    color: #000;
    font-size: 70px;
    font-weight: 500;
    letter-spacing: -3px;
    margin: 0;
    text-align: center;
    text-wrap: balance;
}

.comparisonContainer {
    width: 100%;
    max-width: 900px;
    margin: 75px auto;
    box-sizing: border-box;
}

.comparisonBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 75px 0;
}

.textContainer {
    width: 55%;
}

.comparisonBox h2 {
    color: #1a1a1a;
    font-size: 25px;
    font-weight: 500;
    margin: 0;
    width: 35%;
    text-wrap: balance;
    line-height: 1.3;
}

.comparisonBox p {
    font-size: 16px;
    line-height: 1.7;
    margin-top: 0;
    margin-bottom: 15px;
}

@media only screen and (min-width: 480px) {
    .comparisonBox h2 {
        position: sticky;
        top: 25vh;
        height: 50vh;
    }
}

@media only screen and (max-width: 480px) {
    .comparisonBox {
        margin-top: 50px;
    }

    .title {
        font-size: 40px;
    }

    .comparisonContainer {
        margin: 25px auto;
    }

    .comparisonBlock {
        flex-direction: column;
        padding: 25px;
        margin: 0;
    }

    .textContainer {
        width: 100%;
    }

    .comparisonBox h2 {
        font-size: 28px;
        font-weight: 500;
        margin: 25px 0;
        width: 100%;
    }

    .comparisonBox p {
        font-size: 16px;
        line-height: 1.5;
    }
}
