
@media only screen and (min-width: 480px) {
    .pointsBox {
        display: flex;
        position: absolute;
        left: 0;
    }
    .pointBox {
        display: flex;
        position: absolute;
        align-items: center;
        transition: top 0.2s ease-in-out;
    }
    .point{
        width: 3px;
        height: 16px;
        border-radius: 100px;
        background-color: white;
    }
}
@media only screen and (max-width: 480px) {
    .pointsBox {
        display: flex;
        position: absolute;
    }
    .pointBox {
        display: flex;
        justify-content: center;
        position: absolute;
        align-items: center;
        transition: left 0.25s ease-in-out;
    }
    .point{
        width: 55px;
        height: 55px;
        z-index: -1;
        border-radius: 50%;
        background-color: white;
    }
}
