
.title {
    font-size: 24px;
    margin-bottom: 20px;
}

.slideContent {
    font-size: 18px;
    text-align: center;
}

.slideControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.button {
    background-color: #000;
    border: 2px solid #000;
    border-radius: 15px;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    padding: 10px 22px;
    text-align: center;
    text-decoration: none;
    transition: .2s;
}

.pushButtonBox {
    display: flex;
    justify-content: center;
    margin: 0;
}

.image {
    width: 100%;
    border-radius: 20px;
}
