
.headerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1em 2em 0;
  box-sizing: border-box;
}

.menuButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 3px;
  border-radius: 20px;
  margin: 0;
  border: 2px solid black;
  background: black;
  color: white;
  fill: white;
  transition: 0.2s;
}

@media only screen and (min-width: 480px) {
  .menuButton:hover {
    background: white;
    color: black;
    fill: black;
  }
}

.avatar {
  width: 45px;
  border-radius: 15px;
}
.arrow {
  width: 20px;
  padding: 0px 10px;
}

.appContainer {
  width: 90%;
  margin: 10px auto 0;
}


.dropdownMenu {
  position: absolute;
  right: 2em;
  top: 80px;
  background: black;
  border-radius: 15px;
  z-index: 10;
}

.menuItem {
  display: block;
  background: none;
  cursor: pointer;
  color: white;
  width: 155px;
  padding: 12px 0;
  border-radius: 15px;
  border: 3px solid black;
  transition: 0.2s;
  font-family: Montserrat;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
}

.menuItem:hover {
  background: white;
  color: black;
}

@media only screen and (max-width: 480px) {
  .dropdownMenu {
    top: 85px;
  }
  .menuItem {
    width: 185px;
  }
}

/* Language switcher */
.headerButtons {
  display: flex;
  align-items: center;
}
.button {
  margin-right: 10px;
  background-color: transparent;
  border-radius: 12px;
  padding: 13px;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  color: #222;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-family: Montserrat;
  transition: opacity 0.3s ease-in-out, background-color 0.2s;
  position: relative;
}
.button:hover {
  background-color: rgba(117,117,117,.1);
}
