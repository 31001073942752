
.word {
    transition: color 0.5s ease;
    font-size: 45px;
    font-weight: 500;
}

@media screen and (max-width: 768px) {
    .word {
        font-size: 2rem;
    }
}
