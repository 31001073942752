
.contactForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 60px auto 150px;
    box-sizing: border-box;
}

.title {
    color: #000;
    font-size: 60px;
    font-weight: 500;
    letter-spacing: -2px;
    margin: 80px 0 65px;
    text-align: center;
    text-wrap: balance;
}

.formBox {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    flex-direction: column;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    display: flex;

    width: 100%;
    max-width: 48rem;
    margin-left: auto;
    margin-right: auto;
}

.formColumns {
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
}

.formColumn {
    width: 48%;
    flex-direction: column;
    display: block;
}

.formFieldWrapper {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 25px;
}

.fieldLabel {
    margin-bottom: 0.6rem;
    font-weight: 500;
    color: #000;
    font-size: 13px;
}

.formInput {
    height: auto;
    min-height: 2.75rem;
    color: #000;
    background-color: rgba(117,117,117,.1);
    border: 2px solid #dcdad6;
    border-radius: 16px;
    margin-bottom: 0;
    padding: 0.7rem 1rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.6;
    transition: border-color .2s cubic-bezier(.39,.575,.565,1);
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    font-family: Montserrat;
}
.formInput:hover {
    border-color: #9e9d9b;
}
.formInput:focus {
    border-color: #187fe5;
}

.submitButton {
    color: #fff;
    text-align: center;
    background-color: #080808;
    border: none;
    border-radius: 16px;
    padding: 1.1rem 1.5rem;
    font-size: 14px;
    font-weight: 600;
    font-family: Montserrat;
    cursor: pointer;
    transition: background-color .2s cubic-bezier(.39,.575,.565,1);
    width: 100%;
}
.submitButton:hover {
    background-color: #4e4e4e;
}

@media only screen and (max-width: 480px) {
    .contactForm {
        padding: 0 20px;
        margin-top: 30px;
    }
    .title {
        font-size: 40px;
    }
    .formColumns {
        flex-direction: column;
    }
    .formColumn {
        width: 100%;
    }
}
