
.fileUpload {
    display: block;
    margin-top: 30px;
}

.hiddenInput {
    display: none;
}

.customButton {
    cursor: pointer;
    padding: 10px 15px;
    transition: 0.2s;
    font-weight: 600;
    border-radius: 10px;
    margin-right: 5px;
    background: white;
    color: black;
    border: 2px solid white;
    margin-top: 30px;
    font-size: 12px;
}
.customButton:hover {
    background: black;
    color: white;
}

.fileName {
    font-size: 13px;
    cursor: default;
    margin-left: 10px;
}
