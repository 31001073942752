
.container {
    margin: 20px;
    cursor: default;
}

.button {
    cursor: pointer;
    padding: 10px 0;
    border: none;
    background: none;
    transition: 0.2s;
    color: white;
    font-weight: 600;
}
.button:hover {
    color: #cacaca;
}

.inputBox {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.input {
    padding: 10px 15px;
    border-radius: 10px;
    width: 70%;
    outline: none;
    margin-right: 15px;
    background: white;
}

.inputNumber {
    padding: 10px 15px;
    border-radius: 10px;
    width: 55px;
    outline: none;
    background: white;
}

.select {
    padding: 10px;
    border-radius: 10px;
    width: 70%;
    outline: none;
    background: #fff;
    cursor: pointer;
    color: black;
    width: 170px;
    margin: 0 15px;
}
.highlight {
    border: 1px solid red;
    color: red;
}

.saveButton {
    cursor: pointer;
    padding: 10px 15px;
    transition: 0.2s;
    font-weight: 600;
    border-radius: 10px;
    margin-right: 5px;
    background: white;
    color: black;
    border: 2px solid white;
    margin-top: 30px;
    font-size: 13px;
}
.saveButton:hover {
    background: black;
    color: white;
}

.number {
    color: white;
    font-weight: 600;
    margin: auto 5px auto 0;
    width: 60px;
    cursor: default;
}

.text {
    font-size: 14px;
    margin-top: 30px;
    cursor: default;
}

.textZigbee {
    font-size: 14px;
    cursor: default;
    font-weight: 600;
    margin: 10px 0;
}

.title {
    cursor: default;
}

.deleteBox {
    margin-top: 35px;
}
