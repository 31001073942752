
.landingPage {
  width: 100%;
}

.landingContentContainer {
  align-content: center;
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0px;
  height: min-content;
  justify-content: center;
  overflow: visible;
  padding: 132px 0 0;
  position: relative;
  width: 100%;
  z-index: 1;
}

/* First frame */
.frameFirstContainer{
  width: 100%;
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 24px;
  height: min-content;
  justify-content: center;
  overflow: visible;
  padding: 0;
  position: relative;
  transform: perspective(1200px);
  width: min-content;
}
.firstFrameBox {
  text-align: center;
}
.firstFrameTitle {
  font-size: 52px;
  line-height: 48px;
  font-weight: 500;
  letter-spacing: -1px;

  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}
.firstFrameSubtitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -1px;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}
.firstFrameButton {
  color: rgb(82, 99, 255);
  background-color: #eeefff;
  padding: 10px 14px;
  font-size: 18px;
  border-radius: 50px;
  margin: auto;
  margin-bottom: 18px;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: -1px;
  font-family: Montserrat;
  transition: background-color 0.1s ease-in-out;
  text-decoration: none;
  display: inline-block;
}
.firstFrameButton:hover {
  background-color: #dfe0ff;
}

@media only screen and (max-width: 480px) {
  .firstFrameTitle {
    font-size: 40px;
    line-height: 50px;
  }
  .firstFrameSubtitle {
    font-size: 18px;
    margin-top: 10px;
  }
  .firstFrameButton {
    margin: 15px auto 25px;
  }
}

/* Second frame */
.frameSecondContainer {
  width: 100%;
  max-width: 1200px;
  padding: 24px;
  box-sizing: border-box;
  position: relative;
  margin-top: 30px;
  overflow: hidden;
}
.frameSecondImage {
  width: 90%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}

.landingImage {
  border-radius: 2em;
  max-height: 100%;
  max-width: 100%;
  /* 1, 4, 5, 7, 9 */
  background-image: url(../../../public/images/home/smart_home_9.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  position: absolute;
  width: 70%;
  height: 60%;
  left: 50%;
  transform: translate(-50%, 30%);
}

@media only screen and (max-width: 480px) {
  .landingImage {
    width: 96%;
    transform: translate(-50%, 25%);
  }
  .frameSecondImage {
    width: 175%;
  }
}

/* Third frame */
.frameThirdContainer {
  width: 85%;
  max-width: 800px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  margin: 120px auto 200px;
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .frameThirdContainer {
    margin: 60px auto 150px;
  }
}

/* Vision block frame */
.visionContainer {
  width: 100%;
  overflow: scroll;
  max-width: 1100px;
  display: flex;
  justify-content: center;
}
.visionImage {
  width: 100%;
  border-radius: 25px;
  margin: 25vh auto 10vh;
}
@media only screen and (max-width: 480px) {
  .visionImage {
    height: 100vh;
    width: auto;
    max-width: max-content;
    transform: translateX(-75px);
    border-radius: 0;
  }
}

/* PrivacyPage */
.privacyHeaderSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2em 0 0 3em;
  position: fixed;
  top: 0;
  box-sizing: border-box;
}

.privacyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
  margin: auto;
  padding: 3em 0;
  box-sizing: border-box;
  flex-direction: column;
  cursor: default;
}
.privacyContainer h1 {
  text-align: center;
}
.privacyContainer h2 {
  width: 100%;
  margin-top: 25px;
}
.privacyContainer p {
  width: 100%;
  margin: 10px 0;
}

@media only screen and (max-width: 480px) {
  .privacyHeaderSection {
    padding: 1em 2em 0 2em;
    position: relative;
  }

  .privacyContainer {
    width: 100%;
    margin: auto;
    padding: 1em 2em 1em;
  }
}
