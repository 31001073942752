
.buttonBox {
    display: flex;
    margin-top: 25px;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 15px;
}

.languageButton {
    width: 200px;
    border-radius: 12px;
    padding: 13px;
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
    color: white;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-family: Montserrat;
    transition: opacity 0.3s ease-in-out, background-color 0.2s;
    position: relative;
    background-color: rgba(117, 117, 117, .1);
}

.active {
    background-color: rgb(255, 255, 255);
    color: #000000;
    cursor: default;
}

@media only screen and (max-width: 480px) {
    .languageButton {
        width: 100%;
    }
}

@media only screen and (min-width: 481px) {
    .languageButton:hover {
        background-color: rgb(255, 255, 255);
        color: #000000;
    }
}
