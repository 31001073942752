
.root {
    box-sizing: border-box;
}

@media only screen and (min-width: 480px) {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 100px;
        width: 70%;
        margin: auto;
    }
    .container {
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding-left: 115px;
    }
}
@media only screen and (max-width: 480px) {
    .header {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        padding: 20px 25px;
        align-items: center;
        min-height: 100px;
        flex-direction: column;
    }
    .container {
        margin: 0;
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 0 25px;
    }
}

.title {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 20px;
    cursor: default;
}

@media only screen and (min-width: 480px) {
    .allComponentsBox {
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .floorBox {
        width: 600px;
        height: 100%;
        padding: 10px;
        background: white;
        border-radius: 60px;
        position: relative;
        overflow: hidden;
        margin-bottom: 30px;
        position: -webkit-sticky;
        position: -moz-sticky;
        position: -ms-sticky;
        position: -o-sticky;
        position: sticky;
        top: 60px;
    }
    .devicesBox {
        display: block;
        width: calc(100% - 680px);
        margin-bottom: 25px;
    }
    .devicesBoxFullWidth {
        display: block;
        width: 100%;
        margin-bottom: 25px;
    }
    .buttonGroupBox {
        margin-left: 55px;
    }

    .sideBlockLeft {
        display: block;
        width: 25%;
    }
    .sideBlockRight {
        display: block;
        width: 25%;
    }
}
@media only screen and (max-width: 480px) {
    .allComponentsBox {
        width: 100%;
        display: flex;
    }
    .floorBox {
        display: none;
    }
    .devicesBox {
        margin-left: 0;
        width: 100%;
        margin-bottom: 25px;
    }
    .devicesBoxFullWidth {
        margin-left: 0;
        width: 100%;
        margin-bottom: 25px;
    }

    .buttonGroupBox {
        display: none;
    }

    .sideBlockLeft {
        display: block;
        width: 100%;
        margin: 40px 0 20px;
    }
    .sideBlockRight {
        display: none;
    }
}

@media only screen and (min-width: 481px) and (max-width: 1000px) {
    .allComponentsBox {
        flex-direction: column;
    }
    .floorBox {
        width: 90%;
        margin-bottom: 20px;
        position: relative;
        top: 0;
    }
    .devicesBox {
        width: 100%;
        margin-left: 0;
    }
    .devicesBoxFullWidth {
        width: 100%;
        margin-left: 0;
    }
    .buttonGroupBox {
        margin-top: 15px;
    }
}

.floorImg {
    width: 100%;
}
