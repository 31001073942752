.container {
    padding: 20px 0;
    position: relative;
    color: white;
}

@media only screen and (min-width: 768px) {
    .formGroup {
        margin-bottom: 20px;
        margin-top: 40px;
    }
}
@media only screen and (max-width: 768px) {
    .formGroup {
        margin-bottom: 20px;
        margin-top: 65px;
    }
}

.title {
    cursor: default;
}

.text {
    line-height: 26px;
    width: 90%;
}

@media only screen and (max-width: 480px) {
    .text {
        width: 100%;
    }
}

.block {
    display: flex;
    width: 100%;
    max-width: 800px;
    justify-content: space-between;
}

.blockGray {
    display: block;
    padding: 25px 20px;
    background: #1c1c1e;
    border-radius: 25px;
    margin: 35px 20px 0 0;
    box-sizing: border-box;
    width: 100%;
    max-width: 800px;
}

.input {
    padding: 10px 15px;
    border-radius: 10px;
    outline: none;
    margin-right: 15px;
    background: white;
    margin-top: 20px;
    display: block;
    font-size: 14px;
}

.button {
    cursor: pointer;
    padding: 10px 20px;
    transition: 0.2s;
    font-weight: 600;
    border-radius: 15px;
    margin-right: 5px;
    background: white;
    color: black;
    border: 2px solid white;
    margin-top: 30px;
    font-size: 13px;
    font-family: Montserrat;
}
.button:hover {
    background: rgb(255 255 255 / 0%);
    color: white;
}

.name {
    margin-right: 25px;
}

.blockTitle {
    font-size: 16px;
    font-weight: 600;
    margin: 5px 0 10px;
}

.blockRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blockRow > label {
    font-size: 15px;
}
