
@media only screen and (min-width: 480px) {
    .root {
        width: 100%;
        margin-top: 25px;
        padding-left: 60px;
        box-sizing: border-box;
        height: fit-content;
    }
    .buttonsBox {
        display: flex;
        flex-wrap: wrap;
    }
}
@media only screen and (max-width: 480px) {
    .root {
        width: 100%;
    }
    .buttonsBox {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: center;
        grid-gap: 15px;
        grid-column-gap: 10px;
        grid-row-gap: 15px;
        margin-bottom: 25px;
    }
}

.name {
    padding: 0;
    color: #ffffff;
    font-size: 18px;
    cursor: default;
    margin: 5px 0 10px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
}
