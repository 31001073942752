
.backButton {
  cursor: pointer;
  color: #707070;
  position: absolute;
  left: 100px;
  text-decoration: none;
  text-transform: lowercase;
  font-size: 16px;
  transition: 0.2s;
}
.backButton:hover {
  color: #000000;
}
