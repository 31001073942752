
.loginPage {
  width: 100%;
}

.loginContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}

.loginBox {
  margin-bottom: 2rem;
  text-align: center;
  cursor: default;
}
.loginTitle {
  margin: 1rem;
  color: #333;
}

.loginForm {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.loginInput {
  padding: 14px 0 15px;
  background-color: white;
  text-align: center;
  border-radius: 20px;
  border: 2px solid #bbbbbb;
  font-size: 16px;
  transition: 0.2s;
  margin: 0 0 10px;
  outline: none;
  color: black;
}
.loginInput:hover {
  border-color: black;
}
.loginInput:focus {
  border-color: black;
}

.otpContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px
}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  margin: 0 10px;
  font-size: 25px;
  background-color: white;
  text-align: center;
  border-radius: 20px;
  border: 2px solid #bbbbbb;
  transition: 0.2s;
  outline: none;
  color: black;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.otpInput:hover {
  border-color: black;
}
.otpInput:focus {
  border-color: black;
}

.loginButton {
  padding: 16px 0;
  background-color: black;
  color: white;
  text-decoration: none;
  text-align: center;
  border-radius: 20px;
  border: 2px solid black;
  font-size: 17px;
  transition: 0.2s;
  cursor: pointer;
  margin: 5px 0 10px;
  font-weight: 600;
}
.loginButton:hover {
  background-color: white;
  color: black;
}

.logoContainer {
  padding: 2em 3em 0em 3em;
  margin-bottom: -6em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 480px) {
  .logoContainer {
    padding: 1em 2em 0 2em;
    margin-bottom: -5em;
  }
}

.resendButton {
  padding: 0.5rem;
  font-size: 15px;
  border: none;
  background: none;
  color: #333;
  margin-top: 0.5rem;
  cursor: pointer;
}

.privacyText {
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #666666;
  cursor: default;
}
.privacyLink {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.messageText {
  font-size: 17px;
  cursor: default;
  margin: 5px 0;
  color: #c60000;
  text-align: center;
}


.button {
  margin-right: 5px;
  background-color: transparent;
  border-radius: 12px;
  padding: 13px;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  color: #222;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-family: Montserrat;
  transition: opacity 0.3s ease-in-out, background-color 0.2s;
  position: relative;
}

@media only screen and (min-width: 481px) {
  .button:hover {
    background-color: rgba(117,117,117,.1);
  }
}
