
.button {
    border: none;
    fill: white;
    color: white;
    transition: background-color 0.1s ease-in-out;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
}

.buttonLightBackground {
    background-color: rgba(255, 255, 255, 0.15);
}

@media only screen and (min-width: 480px) {
    .button {
        width: 100px;
        height: 100px;
        margin: 0px 15px 15px 0px;
    }
    .textBox {
        width: 100%;
        text-align: left;
        margin: 0px 0px 10px 15px;
        box-sizing: border-box;
    }
    .iconAndStatusBox {
        width: 100%;
        padding: 10px 10px 0px 5px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
    }
}
@media only screen and (max-width: 480px) {
    .button {
        margin: 0;
        width: 100%;
        height: 100px;
    }
    .textBox {
        width: calc(100% - 15px);
        text-align: left;
        position: absolute;
        bottom: 15px;
        left: 15px;
    }
    .iconAndStatusBox {
        width: 100%;
        padding: 15px 10px 0px 5px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
    }
}

@keyframes buttonActiveAnimation {
    0% {
        transform: scale(1);
        box-shadow: none;
    }
    20% {
        transform: scale(1);
        box-shadow: none;
    }
    100% {
        background-color: rgba(0, 0, 0, 0.5);
        transform: scale(1.1);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    }
}

.button:active {
    animation: buttonActiveAnimation 500ms forwards;
}

.sensor {
    cursor: default;
}

.activeDevice {
    background-color: rgba(23, 87, 255, 1);
}
.activeSensor {
    background-color: rgba(220, 80, 96, 1);
}

.title {
    margin: 0;
    padding: 0;
    font-size: 11px;
    font-weight: 600;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 15px);
}
.text {
    margin: 0;
    padding: 0;
    font-size: 11px;
    font-weight: 600;
    color: #e3e3e3;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 15px);
}

.status {
    margin: 0;
    font-weight: 600;
    font-size: 13px;
    text-align: right;
    max-width: 50%;
}

.buttonCurtain {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #4b3de3;
    z-index: -1;
    transition: 4s ease-in-out;
}
