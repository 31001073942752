
.notificationChannels {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 25px;
}

.box {
    display: flex;
    margin-top: 10px;
}

.addUserButton {
    font-family: Montserrat;
    background: #1757ff;
    color: white;
    width: 280px;
    height: 50px;
    border-radius: 100px;
    margin: 25px 0;
    padding: 0;
    cursor: pointer;
    transition: 0.2s;
}
.addUserButton:hover {
    background: #0d3ebe;
}

.deleteButton {
    font-family: Montserrat;
    background: #ff7857;
    color: white;
    width: 280px;
    height: 50px;
    border-radius: 100px;
    margin: 25px 0;
    padding: 0;
    cursor: pointer;
    transition: 0.2s;
}
.deleteButton:hover {
    background: #e65a3f;
}
