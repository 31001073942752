.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 50px;
    background: white;
    padding: 25px;
    box-sizing: border-box;
    margin-right: 15px;
    flex-grow: 1;
    min-width: 45%;
    max-width: 60%;
}
@media only screen and (min-width: 480px) {
    .root {
        min-width: 165px;
    }
}

.rootTemperature {
    justify-content: space-between;
}

.dayOfWeek {
    font-size: 18px;
    color: black;
    margin: 0;
    font-weight: 500;
    text-transform: capitalize;
}

.date {
    font-size: 15px;
    color: black;
    margin: 0;
    margin-top: 5px;
}

.temperature {
    margin: 0;
    font-weight: 500;
    font-size: 15px;
}

.home {
    margin: 0;
    font-size: 12px;
    color: #9a9a9a;
    margin-top: 2px;
}
