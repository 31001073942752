
.button {
    position: absolute;
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 20px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background 0.1s ease-in-out;
    fill: background 0.1s ease-in-out;
    background: #d5e6ff;
    fill: #1757ff;
    color: #1757ff;
}

.sensor {
    cursor: default;
}

.activeDevice {
    background: #1757ff;
    fill: white;
    color: white;
}
.activeSensor {
    background: #dc5060;
    fill: white;
    color: white;
}
.activeCurtain {
    background: #4b3de3;
    fill: white;
    color: white;
}
