
.root {
    box-sizing: border-box;
}

@media only screen and (min-width: 480px) {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 100px;
        width: 70%;
        margin: auto;
    }
    .container {
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding-left: 115px;
    }
    .allComponentsBox {
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .sideBlockLeft {
        display: block;
        width: 25%;
    }
    .sideBlockRight {
        display: block;
        width: 25%;
    }
}
@media only screen and (max-width: 480px) {
    .header {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        padding: 20px 30px;
        align-items: center;
        min-height: 100px;
        flex-direction: column;
    }
    .container {
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding-left: 30px;
        padding-right: 30px;
    }
    .allComponentsBox {
        width: 100%;
        display: flex;
    }

    .sideBlockLeft {
        display: block;
        width: 100%;
        margin: 40px 0 20px;
    }
    .sideBlockRight {
        display: none;
    }
}

.title {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 20px;
    cursor: default;
}

@media only screen and (min-width: 480px) {
    .title {
        margin-top: 30px;
    }
}

.subtitle {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 14px;
    cursor: default;
    margin-top: 10px;
}
