
.capabilityBox {
    width: 100%;
    display: grid;
    grid-template-rows: repeat(1, minmax(0, 1fr));
    grid-template-columns: repeat(2, minmax(0, 1fr));
    border-radius: .375rem;
    background-color: #ffffff;
    overflow: hidden;
    aspect-ratio: 140 / 67;
}

.capabilityText {
    text-align: left;
    padding: 5rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;
}

.capabilityTitle {
    font-size: 23px;
    text-align: left;
    font-weight: 500;
    white-space: break-spaces;
    line-height: 2rem;
    padding: 0;
    margin: 0;
}

.capabilityDescription {
    font-size: 14px;
    text-align: left;
    font-weight: 400;
    white-space: break-spaces;
    line-height: 1.4rem;
    color: #676767;
    padding: 0;
    margin: 0;
}

.capabilityImage {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-width: 100%;
    display: block;
}

@media only screen and (max-width: 480px) {
    .capabilityBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        aspect-ratio: auto;
    }
    .capabilityText {
        padding: 2rem;
        text-align: center;
    }
    .capabilityTitle {
        width: 100%;
        font-size: 28px;
        line-height: 2rem;
        text-align: center;
    }
    .capabilityDescription {
        width: 100%;
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: center;
    }
    .capabilityImage {
        min-height: 285px;
    }
}
