
.rootNotSelected {
    fill: rgb(255 255 255 / 60%);
    color: rgb(255 255 255 / 0%);
}

@media only screen and (min-width: 480px) {
    .root {
        width: 50px;
        height: 50px;
        border-radius: 100px;
        margin: 5px 0px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        background-color: rgb(0 0 0 / 10%);
        transition: fill 0.2s ease-in-out;
    }

    .rootSelected {
        fill: rgb(255 255 255);
        color: rgb(255 255 255 / 0%);
    }

    .rootNotSelected:hover {
        fill: rgb(255 255 255);
        color: rgb(255 255 255 / 0%);
    }
}
@media only screen and (max-width: 480px) {
    .root {
        width: 55px;
        height: 55px;
        border-radius: 100px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.2s ease;
    }

    .rootSelected {
        fill: black;
        color: black;
    }
}
