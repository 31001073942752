.featuresContainer {
    display: flex;
    justify-content: center;
    gap: 100px;
    width: 95%;
    max-width: 850px;
    margin: auto;
    margin-bottom: 150px;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 70px;
    opacity: 0;
    width: calc(50% - 50px);
}

.featureBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    transform: translate(0);
    transition: transform 0.4s ease-in-out;
}

.scroll {
    transform: skew(-2deg);
}

.featureBlock img {
    width: 100%;
    border-radius: 15px;
}

.featureBlock p {
    width: 80%;
    height: 80px;
    margin: 25px auto 0;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}

.slideInLeft {
    opacity: 1;
    transform: translate(0);
    animation: slideInLeft 1s forwards;
}

@keyframes slideInLeft {
    0% {
        opacity: 0;
        transform: translate(-100%, -50px);
    }
    100% {
        opacity: 1;
        transform: translate(0);
    }
}

.slideInRight {
    opacity: 1;
    transform: translate(0);
    animation: slideInRight 1s forwards;
}

@keyframes slideInRight {
    0% {
        opacity: 0;
        transform: translate(100%, 50px);
    }
    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@media only screen and (max-width: 480px) {
    .featuresContainer {
        flex-direction: column;
        gap: 50px;
        width: 75%;
        margin-bottom: 100px;
    }
    .column {
        width: 100%;
        gap: 50px;
    }
    .featureBlock p {
        width: 90%;
        height: auto;
    }
}
