
.block {
    display: grid;
    grid-template-columns: 55px 1fr 1fr 1fr;
    gap: 15px 10px;
    margin-top: 30px;
}

.user {
    display: contents;
}

.userAvatar {
    width: 55px;
    border-radius: 100px;
    margin-right: 15px;
    align-self: center;
}

.userName {
    text-align: left;
    font-weight: 600;
    margin-right: 15px;
    align-self: center;
    font-size: 15px;
    line-height: 22px;
}

.userRole {
    text-align: left;
    align-self: center;
    font-size: 15px;
}

.userButton {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-self: center;
}

.trashButton {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
    background: #f4f4f400;
    color: white;
}
.trashButton:hover {
    background: #f4f4f4;
    color: red;
}

.confirmButton {
    background: #f4f4f4;
    color: black;
}

.addUserButton {
    font-family: Montserrat;
    background: #1757ff;
    color: white;
    width: 240px;
    height: 50px;
    border-radius: 100px;
    margin: 25px 0;
    padding: 0;
    cursor: pointer;
    transition: 0.2s;
}
.addUserButton:hover {
    background: #0d3ebe;
}

.slideBox {
    color: black;
}
.slideTitle {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}
.slideContent {
    font-size: 18px;
    text-align: left;
}

.editBox {
    background: #f4f4f5;
    border-radius: 25px;
    margin: 25px 0;
    padding-top: 5px;
}
@media only screen and (max-width: 768px) {
    .editBox {
        width: 100%;
    }
}

.slidePhoneNumber {
    padding: 20px 25px;
    border-radius: 15px;
    width: 100%;
    outline: none;
    background: none;
    border: none;
    box-sizing: border-box;
    font-size: 14px;
    color: black;
    margin-bottom: 5px;
}

.editButtonsBox {
    display: flex;
    justify-content: space-between;
    padding: 10px 25px 25px;
}

.cancelButton {
    padding: 16px 40px;
    background-color: #9a9a9a;
    color: white;
    text-decoration: none;
    text-align: center;
    border-radius: 50px;
    border: none;
    font-size: 12px;
    transition: 0.2s;
    cursor: pointer;
    font-family: Montserrat;
}
.cancelButton:hover {
    background-color: #7a7a7a;
}

.saveButton {
    padding: 16px 40px;
    background-color: #1757ff;
    color: white;
    text-decoration: none;
    text-align: center;
    border-radius: 50px;
    border: none;
    font-size: 12px;
    transition: 0.2s;
    cursor: pointer;
    font-family: Montserrat;
}
.saveButton:hover {
    background-color: #0d3ebe;
}

.loaderBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorText {
    color: red;
    font-size: 15px;
    text-align: left;
    margin-top: 15px;
    margin-left: 15px;
}

/* For UserSettings */
.userSettingsBlock {
    display: grid;
    justify-items: start;
}
@media only screen and (max-width: 768px) {
    .userSettingsBlock {
        justify-items: end;
    }
}

.settingsAvatars {
    display: flex;
    gap: 10px 5px;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 35px;
}
@media only screen and (max-width: 768px) {
    .settingsAvatars {
        justify-content: center;
    }
}

.settingsAvatar {
    width: 75px;
    height: 75px;
    border-radius: 15px;
    overflow: hidden;
}

.settingsAvatarUnactive {
    padding: 5px;
    border-radius: 20px;
    border: 2px solid #ffffff00;
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
}
.settingsAvatarActive {
    padding: 5px;
    border-radius: 20px;
    border: 2px solid #ffffff;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media only screen and (max-width: 768px) {
    .settingsAvatarUnactive {
        width: calc(25% - 20px);
    }
    .settingsAvatarActive {
        width: calc(25% - 20px);
    }
}

.messageText {
    font-size: 15px;
    cursor: default;
    margin: 0 0 25px;
    color: #ffffff;
    text-align: center;
}

.settingsAvatarImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
