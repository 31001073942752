
.headerBox {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    height: min-content;
    justify-content: space-between;
    left: 50%;
    max-width: 1200px;
    overflow: visible;
    padding: 24px 48px 12px;
    position: fixed;
    top: 0;
    transform: translate(-50%);
    width: 100%;
    z-index: 2;
    box-sizing: border-box;
}

.headerContent {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    z-index: 3;
}

.ctaButton {
    background-color: #080808;
    border-radius: 14px;
    color: #ffffff;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    opacity: 1;
    transition: 0.2s;
    font-family: Montserrat;
}
.ctaButton:hover {
    background-color: #4e4e4e;
}

.button {
    margin-right: 5px;
    background-color: transparent;
    border-radius: 12px;
    padding: 13px;
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
    color: #222;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-family: Montserrat;
    transition: opacity 0.3s ease-in-out, background-color 0.2s;
    position: relative;
}
.button:hover {
    background-color: rgba(117,117,117,.1);
}

@media screen and (max-width: 768px) {
    .headerBox {
        padding: 24px 24px 12px;
    }
}

/* Gradient */
.gradientContainer {
    flex: none;
    height: 150%;
    left: calc(50.00000000000002% - 100% / 2);
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
}

.gradientContainerInner {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0px;
}

.gradientItem1 {
    position: absolute;
    inset: 0px;
    z-index: 1;
    backdrop-filter: blur(0.0625px);
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 12.5%, rgb(0, 0, 0) 25%, rgba(0, 0, 0, 0) 37.5%);
    border-radius: 0px;
    pointer-events: none;
}
.gradientItem2 {
    position: absolute;
    inset: 0px;
    z-index: 2;
    backdrop-filter: blur(0.125px);
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 12.5%, rgb(0, 0, 0) 25%, rgb(0, 0, 0) 37.5%, rgba(0, 0, 0, 0) 50%);
    border-radius: 0px;
    pointer-events: none;
}
.gradientItem3 {
    position: absolute;
    inset: 0px;
    z-index: 3;
    backdrop-filter: blur(0.25px);
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 25%, rgb(0, 0, 0) 37.5%, rgb(0, 0, 0) 50%, rgba(0, 0, 0, 0) 62.5%);
    border-radius: 0px;
    pointer-events: none;
}
.gradientItem4 {
    position: absolute;
    inset: 0px;
    z-index: 4;
    backdrop-filter: blur(0.5px);
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 37.5%, rgb(0, 0, 0) 50%, rgb(0, 0, 0) 62.5%, rgba(0, 0, 0, 0) 75%);
    border-radius: 0px;
    pointer-events: none;
}
.gradientItem5 {
    position: absolute;
    inset: 0px;
    z-index: 5;
    backdrop-filter: blur(1px);
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 62.5%, rgb(0, 0, 0) 75%, rgba(0, 0, 0, 0) 87.5%);
    border-radius: 0px;
    pointer-events: none;
}
.gradientItem6 {
    position: absolute;
    inset: 0px;
    z-index: 6;
    backdrop-filter: blur(2px);
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 62.5%, rgb(0, 0, 0) 75%, rgb(0, 0, 0) 87.5%, rgba(0, 0, 0, 0) 100%);
    border-radius: 0px;
    pointer-events: none;
}
.gradientItem7 {
    position: absolute;
    inset: 0px;
    z-index: 7;
    backdrop-filter: blur(4px);
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 75%, rgb(0, 0, 0) 87.5%, rgb(0, 0, 0) 100%);
    border-radius: 0px;
    pointer-events: none;
}
.gradientItem8 {
    position: absolute;
    inset: 0px;
    z-index: 8;
    backdrop-filter: blur(8px);
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 87.5%, rgb(0, 0, 0) 100%);
    border-radius: 0px;
    pointer-events: none;
}
