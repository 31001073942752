
.iconLeft, .iconRight {
    color: #6b6b6b;
    position: absolute;
    z-index: 1;
    width: 15px;
    top: 3px;
}
.iconLeft {
    left: 6px;
    color: white;
}
.iconRight {
    right: 6px;
}

.toggleContainer {
    width: 50px;
    height: 30px;
    background-color: #ccc;
    border-radius: 15px;
    position: relative;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.toggleContainer.toggled {
    background-color: #007AFF;
}

.toggleCircle {
    width: 26px;
    height: 26px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: transform 0.3s ease;
    z-index: 2;
}

.toggleContainer.toggled .toggleCircle {
    transform: translateX(20px);
}
