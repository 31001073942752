
.container {
    bottom: 30px;
    left: 10px;
    position: fixed;
    z-index: 1000;
    width: calc(100% - 20px);
    background: white;
    color: black;
    min-height: 300px;
    max-height: 92%;
    border-radius: 50px;
    padding: 25px 25px 40px;
    box-sizing: border-box;
    overflow-y: scroll;
    animation: fadeIn 0.3s ease-in-out;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.container.closing {
    animation: fadeOut 0.3s ease-in-out;
}

@media only screen and (min-width: 480px) {
    .container {
        bottom: 15px;
        width: 100%;
        max-width: 500px;
        left: 75%;
        transform: translate(-50%, 0);
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translate(-50%, 100%);
        }
        to {
            opacity: 1;
            transform: translate(-50%, 0%);
        }
    }

    @keyframes fadeOut {
        from {
            opacity: 1;
            transform: translate(-50%, 0%);
        }
        to {
            opacity: 0;
            transform: translate(-50%, 100%);
        }
    }
}
@media only screen and (max-width: 480px) {
    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translateY(100%);
        }
        to {
            opacity: 1;
            transform: translateY(0%);
        }
    }

    @keyframes fadeOut {
        from {
            opacity: 1;
            transform: translateY(0);
        }
        to {
            opacity: 0;
            transform: translateY(100%);
        }
    }
}

@media only screen and (min-width: 481px) and (max-width: 1000px) {
    .container {
        left: 50%;
    }
}

.closeIconBtn {
    position: sticky;
    top: 0;
    left: 100%;
    background: #f4f4f4;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.2s;
}
.closeIconBtn:hover {
    background: #e4e4e4;
}
.closeIcon {
    width: 25px;
    stroke-width: 2.5;
    color: black;
}

.content {
    margin-top: 25px;
}
