
.buttonGroup {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
}

.button {
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 12px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: #e3e3e3;
    border-radius: 50px;
}

.button.active {
    color: #000;
}
  
.background {
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 6px 9px 0px;
}

.buttonGroupBox {
    background-color: rgb(0 0 0 / 15%);
    padding: 5px;
    border-radius: 50px;
}

@media only screen and (max-width: 480px) {
    .buttonGroupBox {
        margin: auto;
    }
}
