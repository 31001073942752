html{
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Убираем серое подсвечивание при касании на iOS, делая его прозрачным */
  -webkit-tap-highlight-color: transparent;

  background: white;
  color: black;

  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 400px) {
  body {
    zoom: min(90%, calc(var(--page-width) / 400 * 100%));
  }
}

* {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

::-webkit-scrollbar {
  display: none;
}

*:focus {
  outline: none !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 

  outline-style: none;
  outline-width: 0px !important;
  outline-color: none !important;
}

button, a, input, textarea {
  outline: 0 !important;
  outline-color: transparent !important;
  outline-width: 0 !important;
  outline-style: none !important;

  background: none;
  border: none;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

div {
  outline: 0 !important;
  outline-color: transparent !important;
  outline-width: 0 !important;
  outline-style: none !important;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
}

/* font Open Sans */
@font-face {
  font-family: 'Open Sans';
  src: url('./../fonts/OpenSans/OpenSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./../fonts/OpenSans/OpenSans-Bold.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./../fonts/OpenSans/OpenSans-Italic.ttf');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./../fonts/OpenSans/OpenSans-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

/* font Montserrat */
@font-face {
  font-family: 'Montserrat';
  src: url('./../fonts/Montserrat/Montserrat-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./../fonts/Montserrat/Montserrat-Bold.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./../fonts/Montserrat/Montserrat-Italic.ttf');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./../fonts/Montserrat/Montserrat-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./../fonts/Montserrat/Montserrat-Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* font Schibsted Grotesk */
@font-face {
  font-family: 'Schibsted Grotesk';
  src: url('./../fonts/SchibstedGrotesk/SchibstedGrotesk-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Schibsted Grotesk';
  src: url('./../fonts/SchibstedGrotesk/SchibstedGrotesk-Bold.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Schibsted Grotesk';
  src: url('./../fonts/SchibstedGrotesk/SchibstedGrotesk-Italic.ttf');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Schibsted Grotesk';
  src: url('./../fonts/SchibstedGrotesk/SchibstedGrotesk-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Schibsted Grotesk';
  src: url('./../fonts/SchibstedGrotesk/SchibstedGrotesk-Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* font Proxima Nova */
@font-face {
  font-family: 'Proxima Nova';
  src: url('./../fonts/ProximaNova/proximanova_regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./../fonts/ProximaNova/proximanova_bold.otf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./../fonts/ProximaNova/proximanova_blackit.otf');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./../fonts/ProximaNova/proximanova_boldit.otf');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

/* font Manrope */
@font-face {
  font-family: 'Manrope';
  src: url('./../fonts/Manrope/Manrope-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./../fonts/Manrope/Manrope-Bold.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./../fonts/Manrope/Manrope-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./../fonts/Manrope/Manrope-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./../fonts/Manrope/Manrope-Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./../fonts/Manrope/Manrope-Light.ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./../fonts/Manrope/Manrope-ExtraLight.ttf');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

/* font Plus Jakarta Sans */
@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('./../fonts/PlusJakartaSans/PlusJakartaSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('./../fonts/PlusJakartaSans/PlusJakartaSans-Bold.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('./../fonts/PlusJakartaSans/PlusJakartaSans-Italic.ttf');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('./../fonts/PlusJakartaSans/PlusJakartaSans-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
